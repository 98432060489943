import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessor } from '@ngneat/reactive-forms';

import { SelectButton } from '@ebf-libs/sdk/platform/forms/lib/domain/select-button.interface';
import isEqual from 'lodash/isEqual';

@Component({
  selector: 'ebf-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectButtonComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent implements ControlValueAccessor {
  @Input()
  public value: boolean;
  @Input()
  public buttons: SelectButton[];
  @Input()
  public fullWidth: boolean;

  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  public onChange(value: boolean) {}
  public onTouched() {}

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: boolean): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.changeDetectorRef.detectChanges();
  }

  public isFirstButton(button: SelectButton): boolean {
    return this.buttons[0] === button;
  }

  public isLastButton(button: SelectButton): boolean {
    return this.buttons[this.buttons.length - 1] === button;
  }

  public getButtonBorderStyles(button: SelectButton): Record<string, any> {
    if (this.isFirstButton(button)) {
      return {
        width: '50%',
        'border-top-right-radius': 0,
        'border-bottom-right-radius': 0,
      };
    } else if (this.isLastButton(button)) {
      return {
        width: '50%',
        'border-top-left-radius': 0,
        'border-bottom-left-radius': 0,
      };
    } else {
      return {
        width: '50%',
        'border-radius': 0,
      };
    }
  }

  public isSelectedButton(button: SelectButton, index: number): boolean {
    return !this.value && !index ? true : isEqual(this.value, button.value);
  }
}
