import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { FormGroup } from '@ngneat/reactive-forms';

import { AbstractFormComponent, EbfAbstractFormFacadeService } from '@ebf-libs/sdk/platform/forms';
import { validateFormFields } from '@ebf-libs/sdk/platform/forms-validation';
import { UserService } from '@ebf/core/user/services/user.service';

export interface LoginForm {
  email: string;
  password: string;
}

@Component({
  selector: 'ebf-layout-login',
  templateUrl: './layout-login.component.html',
  styleUrls: ['./layout-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutLoginComponent extends AbstractFormComponent implements OnInit {
  public form: FormGroup<LoginForm>;
  public isPasswordReset: boolean;
  public isMagicLink: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    protected readonly ebfAbstractFormFacadeService: EbfAbstractFormFacadeService,
  ) {
    super(ebfAbstractFormFacadeService);
  }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  public onMagicLinkToggle(): void {
    this.isMagicLink = !this.isMagicLink;

    if (this.isMagicLink) {
      this.form.controls.password.disable();
    } else {
      this.form.controls.password.enable();
      this.form.controls.password.markAsPristine();
    }
  }

  public onForgotPasswordToggle(): void {
    this.isPasswordReset = !this.isPasswordReset;
    if (this.isPasswordReset) {
      this.form.controls.password.disable();
    } else {
      this.form.controls.password.enable();
    }
    this.form.controls.email.markAsPristine();
    this.form.controls.password.markAsPristine();
  }

  public onFormSubmit(): void {
    validateFormFields(this.form);

    if (this.form.invalid) {
      return;
    }

    this.setRequestPending(true);
    if (this.isPasswordReset) {
      this.resetPassword();
    } else {
      this.login();
    }
  }

  private login(): void {
    const redirectUrl = this.activatedRoute.snapshot.queryParams.backUrl || '/championships';

    if (this.isMagicLink) {
      this.loginViaMagicLink(redirectUrl);
    } else {
      this.loginViaPassword(redirectUrl);
    }
  }

  private getContinueUrlUrl(redirectUrl: string): string {
    return redirectUrl.includes('?')
      ? `${redirectUrl}&email=${this.form.value.email}`
      : `${redirectUrl}?email=${this.form.value.email}`;
  }

  private loginViaMagicLink(redirectUrl: string): void {
    this.subSink.sink = this.userService
      .loginViaMagicLink(this.form.value.email, `${location.origin}${this.getContinueUrlUrl(redirectUrl)}`)
      .pipe(finalize(() => this.setRequestPending(false)))
      .subscribe({
        next: () => {
          this.ebfAbstractFormFacadeService.showSuccessToast(
            'Email with magic link has been sent to email ' + this.form.value.email,
            'Success',
          );
        },
        error: apiError => this.displayErrorMessages(apiError),
      });
  }

  private loginViaPassword(redirectUrl: string): void {
    this.subSink.sink = this.userService
      .login(this.form.value)
      .pipe(finalize(() => this.setRequestPending(false)))
      .subscribe({
        next: () => this.router.navigateByUrl(decodeURIComponent(redirectUrl)),
        error: apiError => this.displayErrorMessages(apiError),
      });
  }

  private resetPassword(): void {
    this.subSink.sink = this.userService
      .resetPassword(this.form.value)
      .pipe(finalize(() => this.setRequestPending(false)))
      .subscribe({
        next: () => {
          this.ebfAbstractFormFacadeService.showSuccessToast(
            'The password has been reset. Check your email and follow the instructions in it.',
            'Success',
          );
          this.onForgotPasswordToggle();
        },
        error: apiError => this.displayErrorMessages(apiError),
      });
  }
}
