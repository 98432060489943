import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { FormBuilder } from '@ngneat/reactive-forms';

import { ListResponse } from '@ebf-libs/sdk/platform/api';
import { UsersApiService } from '@ebf/features/users/services/users-api.service';
import { UsersMapperService } from '@ebf/features/users/services/users-mapper.service';
import { UserDto, UserForm, UsersQuery } from '@ebf/features/users/domain';
import { CountriesApiService } from '@ebf/features/countries/services/countries-api.service';
import { EbfToastService } from '@ebf-libs/sdk/platform/toast';
import { SelectItem, toSelectItemList } from '@ebf-libs/sdk/platform/forms';
import { UserService } from '@ebf/core/user/services/user.service';
import { CompleteRegistrationForm } from '@ebf/core/user/domain';
import { ChampionshipsApiService } from '@ebf/features/championships/services/championships-api.service';
import { ChampionshipDto } from '@ebf/features/championships/domain';

@Injectable({
  providedIn: 'root',
})
export class UsersFacadeService {
  constructor(
    private readonly usersApiService: UsersApiService,
    private readonly championshipsApiService: ChampionshipsApiService,
    private readonly countriesApiService: CountriesApiService,
    private readonly usersMapperService: UsersMapperService,
    private readonly ebfToastService: EbfToastService,
    private readonly userService: UserService,
    public readonly formBuilder: FormBuilder,
  ) {}

  public getUsers(query: UsersQuery): Observable<ListResponse<UserDto>> {
    return this.usersApiService.getList(query);
  }

  public getChampionshipById(uuid: string): Observable<ChampionshipDto> {
    return this.championshipsApiService.getById(uuid);
  }

  public getCountries(): Observable<SelectItem[]> {
    return this.countriesApiService
      .getList()
      .pipe(shareReplay(1), toSelectItemList({ descriptionField: 'flagUrl' }));
  }

  public createUser(formValue: UserForm): Observable<UserDto> {
    return this.usersApiService
      .create(this.usersMapperService.mapTo(formValue))
      .pipe(tap(() => this.ebfToastService.showSuccess('User has been successfully created')));
  }

  public updateUser(formValue: UserForm, userId: string): Observable<void> {
    return this.usersApiService
      .update(this.usersMapperService.mapTo(formValue, userId))
      .pipe(tap(() => this.ebfToastService.showSuccess('User has been successfully updated')));
  }

  public completeRegistration(
    formValue: CompleteRegistrationForm,
    championshipUuid?: string,
  ): Observable<UserDto> {
    const userPayload: Partial<UserDto> = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      country: { id: +formValue.countryId },
      phoneNumber: formValue.phoneNumber,
      uuid: this.getUserSnapshot().uid,
    };
    const request =
      formValue.isModel && championshipUuid
        ? this.championshipsApiService.updateModel(championshipUuid, { user: userPayload })
        : this.usersApiService.update(userPayload);

    return request.pipe(
      switchMap(() => this.userService.refreshCurrentUser()),
      tap(() => this.ebfToastService.showSuccess('Registration has been successfully completed')),
    );
  }

  public isUserAdmin(): boolean {
    return this.userService.isAdmin();
  }

  public getUserSnapshot(): UserDto {
    return this.userService.getUserSnapshot();
  }
}
