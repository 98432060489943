<ng-container *ngIf="countryName">
  <img *ngIf="hideName" [src]="url" [alt]="countryName" width="22" height="16" (error)="onImageError($event)">

  <div *ngIf="!hideName" class="d-flex align-items-center">
    <img [src]="url" [alt]="countryName" width="22" height="16" (error)="onImageError($event)" loading="lazy">
    <span class="ml-2 {{ styleClass }}">{{ countryName }}</span>
  </div>
</ng-container>


