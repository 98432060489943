<form [formGroup]="form"
      class="fullscreen d-flex justify-content-center align-items-center"
      (ngSubmit)="onFormSubmit()">
  <p-card [ebfPending]="isRequestPending$ | async"
          class="fullscreen-xs position-relative" header="Personal Data"
          [style.min-width]="'360px'">
    <ng-container *ngIf="countries$ | async as countries; else spinner">
      <div>
        <div *ngIf="championship" class="mb-4">
          Championship: <b>{{ championship.title }}</b>
        </div>
        <div class="mb-4">
          <ebf-input [isRequired]="true" label="First Name" formControlName="firstName"></ebf-input>
        </div>
        <div class="mb-4">
          <ebf-input [isRequired]="true" label="Last Name" formControlName="lastName"></ebf-input>
        </div>
        <div class="mb-4">
          <label for="countryId" required class="align-self-start">Country</label>

          <p-dropdown formControlName="countryId"
                      inputId="countryId"
                      optionLabel="label"
                      optionValue="value"
                      placeholder="Select country"
                      styleClass="fullwidth"
                      [filter]="true"
                      [options]="countries">
            <ng-template pTemplate="selectedItem">
              <div class="d-flex" *ngIf="getSelectedCountryData(countries) as country">
                <ebf-country [countryName]="country.label"
                             [url]="country.description"
                             [hideName]="true">
                </ebf-country>
                <span class="ml-3">{{ country.label }}</span>
              </div>
            </ng-template>

            <ng-template let-country pTemplate="item">
              <div class="d-flex">
                <ebf-country [countryName]="country.label"
                             [url]="country.description"
                             [hideName]="true">
                </ebf-country>
                <span class="ml-3">{{ country.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
        <div class="mb-4">
          <ebf-input formControlName="phoneNumber"
                     label="Phone number"
                     placeholder="Phone">
          </ebf-input>
        </div>
        <div *ngIf="championship" class="mb-4">
          <p-checkbox name="isModel"
                      inputId="isModel"
                      [binary]="true"
                      formControlName="isModel">
          </p-checkbox>
          <label for="isModel" class="mb-0 ml-3">I'm a model</label>
        </div>
      </div>

      <footer>
        <ebf-button type="submit" [fullWidth]="true">Submit</ebf-button>
      </footer>
    </ng-container>
  </p-card>
</form>

<ng-template #spinner>
  <div [ebfPending]="true" style="height: 70vh"></div>
</ng-template>
