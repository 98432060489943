import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

import { EbfImageTextModule } from '@ebf-libs/sdk/platform/image-text';

@Component({
  selector: 'ebf-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EbfImageTextModule, NgIf],
})
export class CountryComponent {
  @Input()
  public countryName: string;
  @Input()
  public styleClass: string = 'font-12';
  @Input()
  public hideName: boolean;
  @Input()
  public url: string;

  public onImageError(error: ErrorEvent): void {
    (error.target as HTMLImageElement).style.display = 'none';
  }
}
