import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ebf-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input()
  public label: string;
  @Input()
  public placeholder: string = '';
  @Input()
  public id: string;
  @Input()
  public isBlock: boolean = true;
  @Input()
  public type: string = 'text';
  @Input()
  public isRequired: boolean;
  @Input()
  public tooltip: string;
  @Input()
  public formControlName: string;
  @Input()
  public value: string;
  @Input()
  public options?: any[];
  @Input()
  public optionLabel: string = 'name';
  @Input()
  public optionValue: string = 'id';
  @Input()
  public minDate: Date = new Date();
  @Input()
  public maxDate: Date;
  @Input()
  public disabled: boolean;

  public onInputChange(newValue: string) {
    this.value = newValue;
    this.onChange(newValue);
  }

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public getRadioInputId(option: Record<string, any>): string {
    return option[this.optionLabel]?.split(' ').join('-') ?? this.id;
  }
}
