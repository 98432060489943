import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractControl, FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import isEqual from 'lodash/isEqual';
import { ApiError } from '@ebf-libs/sdk/platform/api';
import { AutoUnsubscribable } from '@ebf-libs/sdk/platform/domain';

import { RiskyButtonComponent } from '../../domain';
import { EbfAbstractFormFacadeService } from '../../services/ebf-abstract-form-facade.service';

@Component({
  template: '',
})
export abstract class AbstractFormComponent<T = any>
  extends AutoUnsubscribable
  implements OnDestroy, RiskyButtonComponent
{
  public abstract form: AbstractControl<T>;
  public isSuccess: boolean;
  public isEditMode: boolean;
  public successTitleKey: string;
  public successMessageKey: string;
  protected initialData: T;
  protected readonly formBuilder: FormBuilder;
  private readonly _isRequestPending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isRequestPending$: Observable<boolean> = this._isRequestPending$.asObservable();

  constructor(protected readonly ebfAbstractFormFacadeService: EbfAbstractFormFacadeService) {
    super();
    this.formBuilder = this.ebfAbstractFormFacadeService.formBuilder;
  }

  public hasUnsavedChanges(currentFormValue?: T): boolean {
    return !this.isSuccess && !isEqual(currentFormValue || this.form.value, this.initialData);
  }

  protected displaySuccessMessage(successMessageParams?: Record<string, any>): void {
    const successTitle = this.ebfAbstractFormFacadeService.translate(
      this.successTitleKey || 'shared.successHeader',
    );
    const successMessage = this.ebfAbstractFormFacadeService.translate(
      this.successMessageKey,
      successMessageParams,
    );

    this.isSuccess = true;
    this.ebfAbstractFormFacadeService.showSuccessToast(successTitle, successMessage);
  }

  protected displayErrorMessages(
    apiError: ApiError,
    keyMapper?: Record<string, string>,
    errorCodeMapper?: Record<string, Record<string, string>>,
  ): void {
    this.setRequestPending(false);
    this.ebfAbstractFormFacadeService.ebfFormErrorsService.setApiErrorsToForm(
      apiError,
      this.form as FormGroup,
      keyMapper,
      errorCodeMapper,
    );
  }

  public setRequestPending(isRequestPending: boolean): void {
    this._isRequestPending$.next(isRequestPending);
  }
}
