import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';

import { EbfToastService } from '@ebf-libs/sdk/platform/toast';
import { UserService } from '@ebf/core/user/services/user.service';

export const isAdminGuard: CanActivateFn = () => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);
  const ebfToastService: EbfToastService = inject(EbfToastService);
  const canActivate: boolean = userService.isAdmin();

  if (!canActivate) {
    ebfToastService.showError('You have no permissions to visit this source');
  }

  return canActivate || router.parseUrl('/championships');
};
