import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe, NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormGroup } from '@ngneat/reactive-forms';
import { PaginatorModule } from 'primeng/paginator';
import { CheckboxModule } from 'primeng/checkbox';

import { CardModule } from 'primeng/card';
import { AbstractFormComponent, EbfFormsModule, SelectItem } from '@ebf-libs/sdk/platform/forms';
import { EbfFormsValidationModule, validateFormFields } from '@ebf-libs/sdk/platform/forms-validation';
import { CompleteRegistrationForm } from '@ebf/core/user/domain';
import { CountryComponent } from '@ebf/shared/country/view/country/country.component';
import { UsersFacadeService } from '@ebf/features/users/services/users-facade.service';
import { ApiError } from '@ebf-libs/sdk/platform/api';
import { ChampionshipDto } from '@ebf/features/championships/domain';

@Component({
  selector: 'ebf-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss'],
  standalone: true,
  imports: [
    CardModule,
    EbfFormsModule,
    EbfFormsValidationModule,
    PaginatorModule,
    ReactiveFormsModule,
    CountryComponent,
    NgIf,
    AsyncPipe,
    CheckboxModule,
  ],
})
export class CompleteRegistrationComponent
  extends AbstractFormComponent<CompleteRegistrationForm>
  implements OnInit
{
  public countries$: Observable<SelectItem[]>;
  public championship?: ChampionshipDto;
  public form: FormGroup<CompleteRegistrationForm>;
  private readonly usersFacadeService: UsersFacadeService = inject(UsersFacadeService);
  private readonly router: Router = inject(Router);
  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  public ngOnInit() {
    const user = this.usersFacadeService.getUserSnapshot();
    this.countries$ = this.usersFacadeService.getCountries();
    this.form = this.formBuilder.group({
      firstName: [user.firstName, [Validators.required]],
      lastName: [user.lastName, [Validators.required]],
      countryId: ['', [Validators.required]],
      phoneNumber: [user.phoneNumber],
      isModel: false,
    });
    this.getChampionship();
  }

  public onFormSubmit(): void {
    if (this.form.invalid) {
      validateFormFields(this.form);

      return;
    }

    this.setRequestPending(true);
    this.subSink.sink = this.usersFacadeService
      .completeRegistration(this.form.getRawValue(), this.getChampionshipUuidFromUrl())
      .pipe(finalize(() => this.setRequestPending(false)))
      .subscribe({
        next: () => {
          this.isSuccess = true;
          this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.backUrl || '/championships');
        },
        error: (apiError: ApiError) => this.displayErrorMessages(apiError),
      });
  }

  public getSelectedCountryData(countries: SelectItem[]): SelectItem {
    return countries?.find((country: SelectItem): boolean => country.value === this.form.value.countryId);
  }

  private getChampionshipUuidFromUrl(): string | null {
    const backUrl: string = this.activatedRoute.snapshot.queryParams.backUrl;

    if (!backUrl) {
      return null;
    }

    const searchParams = new URLSearchParams(backUrl);
    const championshipUuidFromQuery: string | undefined = searchParams.get('championshipUuid');

    if (championshipUuidFromQuery) {
      return championshipUuidFromQuery;
    }

    const countryUuidRegex = /countries\/([\da-fA-F-]+)/;
    const champUuidRegex = /championships\/([\da-fA-F-]+)/;

    return backUrl.match(countryUuidRegex)?.[1] || backUrl.match(champUuidRegex)?.[1];
  }

  private getChampionship(): void {
    const championshipUuid: string | undefined = this.getChampionshipUuidFromUrl();

    if (!championshipUuid) {
      return;
    }

    this.subSink.sink = this.usersFacadeService.getChampionshipById(championshipUuid).subscribe(response => {
      this.championship = response;
      this.changeDetectorRef.detectChanges();
    });
  }
}
