<div class="bg-white fullscreen d-flex align-items-center">
  <img class="d-none d-lg-block side-image"
       src="./assets/images/login-image.png"
       alt="Login image">
  <form [formGroup]="form"
        (ngSubmit)="onFormSubmit()"
        class="px-4 px-md-0">
    <div class="banners d-flex justify-content-center justify-content-lg-between">
      <img src="./assets/images/login-logo-1.svg" alt="Login banner image" class="d-none d-md-block">
      <img src="./assets/images/login-logo-2.svg" alt="Login banner image" class="d-none d-md-block">
      <img src="./assets/images/login-logo-mobile.svg" alt="Login banner image" class="d-block d-md-none">
    </div>
    <h1 class="text-uppercase mb-5 font-family-bold">{{ isPasswordReset ? 'Reset Password' : 'Sign in' }}</h1>
    <div class="mb-4">
      <ebf-input placeholder="Email*"
                 formControlName="email">
      </ebf-input>
    </div>
    <div *ngIf="form.controls.password.enabled" class="mb-5">
      <ebf-input type="password"
                 placeholder="Password*"
                 formControlName="password">
      </ebf-input>
    </div>
    <ng-container *ngIf="isPasswordReset">
      <div class="mt-2 mb-4">
        <ebf-button label="Submit"
                    type="submit"
                    styleClass="text-uppercase"
                    [ebfPending]="isRequestPending$ | async"
                    [fullWidth]="true">
        </ebf-button>
      </div>
      <div class="mb-4 text-center">
        <ebf-button label="Cancel"
                    styleClass="border-0 font-14"
                    theme="outline-black"
                    [fullWidth]="true"
                    (click)="onForgotPasswordToggle()">
        </ebf-button>
      </div>
    </ng-container>
    <ng-container *ngIf="isMagicLink">
      <div class="mt-2 mb-4">
        <ebf-button label="Send link"
                    type="submit"
                    styleClass="text-uppercase"
                    [ebfPending]="isRequestPending$ | async"
                    [fullWidth]="true">
        </ebf-button>
      </div>
      <div class="mt-2 mb-4">
        <ebf-button label="Login via password"
                    styleClass="font-14"
                    theme="outline-black"
                    [fullWidth]="true"
                    (click)="onMagicLinkToggle()">
        </ebf-button>
      </div>
    </ng-container>
    <ng-container *ngIf="!isMagicLink && !isPasswordReset">
      <div class="mt-2 mb-4">
        <ebf-button label="Submit"
                    type="submit"
                    styleClass="text-uppercase"
                    [ebfPending]="isRequestPending$ | async"
                    [fullWidth]="true">
        </ebf-button>
      </div>
      <div class="mt-2 mb-4">
        <ebf-button label="Request a magic link"
                    styleClass="font-14"
                    theme="outline-black"
                    [fullWidth]="true"
                    (click)="onMagicLinkToggle()">
        </ebf-button>
      </div>
      <div class="text-center">
        <ebf-button label="Forgot password"
                    styleClass="border-0 font-14"
                    theme="outline-black"
                    [fullWidth]="true"
                    (click)="onForgotPasswordToggle()">
        </ebf-button>
      </div>
    </ng-container>

    <div *ngIf="!isPasswordReset" class="text-center">
      <ebf-button label="See championships without authorization"
                  styleClass="border-0 font-14"
                  routerLink="/championships"
                  theme="outline-black"
                  [fullWidth]="true">
      </ebf-button>
    </div>
  </form>
</div>
