import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService, HttpMethod, ListQuery, ListResponse } from '@ebf-libs/sdk/platform/api';
import {
  ChampionshipAdvanceParticipantDto,
  ChampionshipCategoriesQuery,
  ChampionshipCategoryDto,
  ChampionshipDto,
  ChampionshipMemberDto,
  ChampionshipMemberPhotosDto,
  ChampionshipMemberRole,
  ChampionshipMembersQuery,
  ChampionshipResultDto,
  ChampionshipRoundDto,
  ChampionshipSkipJudgeDto,
  LinkModelDto,
  RoundContestantsQuery,
} from '@ebf/features/championships/domain';
import { DeepPartial } from '@ebf-libs/sdk';

@Injectable({
  providedIn: 'root',
})
export class ChampionshipsApiService {
  public readonly baseUrl: string = '/championships';
  constructor(private readonly apiService: ApiService) {}

  public getList(query: ListQuery): Observable<ListResponse<ChampionshipDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: this.baseUrl,
      params: query,
    });
  }

  public getById(uuid: string): Observable<ChampionshipDto> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}`,
    });
  }

  public approve(uuid: string): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/approve`,
      body: null,
    });
  }

  public open(uuid: string): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/open`,
      body: null,
    });
  }

  public reopen(uuid: string): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/reopen`,
      body: null,
    });
  }

  public complete(uuid: string): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/complete`,
      body: null,
    });
  }

  public completeCategory(uuid: string, categoryUuid: string): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/categories/${categoryUuid}/complete`,
      body: null,
    });
  }

  public getCategories(
    uuid: string,
    query: ChampionshipCategoriesQuery,
  ): Observable<ListResponse<ChampionshipCategoryDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/categories`,
      params: query,
    });
  }

  public getCategoryById(
    uuid: string,
    categoryUuid: string,
    withInactiveRounds?: boolean,
  ): Observable<ChampionshipCategoryDto> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/categories/${categoryUuid}`,
      params: { withInactiveRounds },
    });
  }

  public getCountries(uuid: string): Observable<ListResponse<ChampionshipDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/country-championships`,
      params: { page: 0, size: 1000 },
    });
  }

  public getCountryById(uuid: string, countryUuid: string): Observable<ChampionshipDto> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/country-championships/${countryUuid}`,
    });
  }

  public updateCountry(
    uuid: string,
    countryUuid: string,
    payload: DeepPartial<ChampionshipDto>,
  ): Observable<ChampionshipDto> {
    return this.apiService.request({
      method: HttpMethod.PUT,
      url: `${this.baseUrl}/${uuid}/country-championships/${countryUuid}`,
      body: payload,
    });
  }

  public updateCategory(
    uuid: string,
    categoryUuid: string,
    payload: DeepPartial<ChampionshipCategoryDto>,
  ): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.PUT,
      url: `${this.baseUrl}/${uuid}/categories/${categoryUuid}`,
      body: payload,
    });
  }

  public getMembers(
    uuid: string,
    query: ChampionshipMembersQuery,
  ): Observable<ListResponse<ChampionshipMemberDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/members`,
      params: query,
    });
  }

  public getMemberById(uuid: string, memberUuid: string): Observable<ChampionshipMemberDto> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/members/${memberUuid}?type=MODEL`,
    });
  }

  public uploadMemberPhotos(
    uuid: string,
    memberUuid: string,
    payload: ChampionshipMemberPhotosDto,
  ): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.PUT,
      url: `${this.baseUrl}/${uuid}/members/${memberUuid}/photos`,
      body: payload,
    });
  }

  public createMember(uuid: string, payload: DeepPartial<ChampionshipMemberDto>): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/members`,
      body: payload,
    });
  }

  public deleteMember(
    uuid: string,
    memberUuid: string,
    type: ChampionshipMemberRole,
    category?: string,
  ): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.DELETE,
      url: `${this.baseUrl}/${uuid}/members/${memberUuid}`,
      params: { type, category },
    });
  }

  public linkModel(uuid: string, payload: LinkModelDto): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/models/link`,
      body: payload,
    });
  }

  public updateModel(uuid: string, payload: DeepPartial<ChampionshipMemberDto>): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/models`,
      body: payload,
    });
  }

  public updateMember(uuid: string, payload: DeepPartial<ChampionshipMemberDto>): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.PUT,
      url: `${this.baseUrl}/${uuid}/members/${payload.uuid}`,
      body: payload,
    });
  }

  public advanceModel(
    uuid: string,
    participantUuid: string,
    payload: ChampionshipAdvanceParticipantDto,
  ): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/members/${participantUuid}/advance`,
      body: payload,
    });
  }

  public skipJudge(uuid: string, judgeUuid: string, payload: ChampionshipSkipJudgeDto): Observable<void> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: `${this.baseUrl}/${uuid}/members/${judgeUuid}/skip`,
      body: payload,
    });
  }

  public getResults(uuid: string, query: ListQuery): Observable<ListResponse<ChampionshipResultDto>> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/results`,
      params: query,
    });
  }

  public create(payload: DeepPartial<ChampionshipDto>): Observable<ChampionshipDto> {
    return this.apiService.request({
      method: HttpMethod.POST,
      url: this.baseUrl,
      body: payload,
    });
  }

  public update(payload: DeepPartial<ChampionshipDto>): Observable<ChampionshipDto> {
    return this.apiService.request({
      method: HttpMethod.PUT,
      url: `${this.baseUrl}/${payload.uuid}`,
      body: payload,
    });
  }

  public getRoundById(
    categoryUuid: string,
    roundUuid: string,
    language: string,
  ): Observable<ChampionshipRoundDto> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `/categories/${categoryUuid}/rounds/${roundUuid}`,
      params: { language },
    });
  }

  public getRoundContestants(uuid: string, params: RoundContestantsQuery): Observable<any> {
    return this.apiService.request({
      method: HttpMethod.GET,
      url: `${this.baseUrl}/${uuid}/round-contestants`,
      params: params,
    });
  }
}
