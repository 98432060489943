import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { concatMap } from 'rxjs/operators';

import { UserService } from '@ebf/core/user/services/user.service';
import { environment } from '../../../../../../environments/environment';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {
  constructor(private readonly userService: UserService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token: string = this.userService.getTokenSnapshot();

    if (!token || request.url.includes('/token') || request.url.includes(environment.api.strapiBaseUrl)) {
      return next.handle(request);
    }

    if (this.userService.isTokenExpired(token)) {
      return this.userService
        .refreshSession()
        .pipe(
          concatMap(() =>
            next.handle(this.getAuthorizedRequest(request, this.userService.getTokenSnapshot())),
          ),
        );
    }

    return next.handle(this.getAuthorizedRequest(request, token));
  }

  private getAuthorizedRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
